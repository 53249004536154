
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import { IonGrid, IonRow, IonCol, IonIcon, alertController } from '@ionic/vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import { addUserRole, revokeUserRole, updateUserRole } from '@/common/records';

export default defineComponent({
    name: "Edit Employees Info",
    components: {
        IonGrid, IonRow, IonCol, IonIcon
    },
    data() {
        return {
            locations: [] as any[],
            roles: [] as any[],
            insLocation: {} as any,
            employees: [] as any[],
            selectedEmployee: {} as any,
            selectedUser: {} as any,
            users: [] as any[],
            userFilter: '',
            showWorkers: false,
            searchUser: false,
            selectedRole: '',
            status: ''
        }
    },
    mounted() {
        this.loadOwnLocation()
        this.loadRoles()
    },
    methods: {
        resetVariables() {
            this.locations = [] as any[]
            this.roles = [] as any[]
            this.employees = [] as any[]
            this.selectedEmployee = {} as any,
            this.selectedUser = {} as any
            this.users = [] as any[]
            this.userFilter = ''
            this.showWorkers = false
            this.searchUser = false
            this.selectedRole = ''
            this.status = ''
            this.loadOwnLocation()
            this.loadRoles()
        },
        async loadOwnLocation() {
            const uniquekey = 'cache:wma:own-locations'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.locations = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/own_location/"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.locations = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }

            if(this.locations.length == 1) {
                this.insLocation = this.locations[0]
            }
        },
        async loadRoles() {
            const uniquekey = 'cache:wma:roles'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.roles = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/roles/"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.roles = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        async loadEmployees() {
            const uniquekey = 'cache:wma:employees-' + this.insLocation?.id
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.employees = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config["host_ip"], config["host_port"], '/workers/', this.insLocation.id))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.employees = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        getEmployeeName(employee: any): any {
            if (Object.keys(employee).length == 0) {
                return null
            }
            const labelData = ''.concat(employee.user_data.user_data.first_name, ' ', employee.user_data.user_data.last_name)
            if (labelData.length > 1) {
                return ''.concat(employee.user_data.user_data.first_name, ' ', employee.user_data.user_data.last_name)
            } else {
                return employee.user_data.user_data.username
            }
        },
        getImageURL(employee: any): any {
            if (employee.user_data.user_img) {
                return config['host_ip'] + config['host_port'] + employee.user_data.user_img
            } else {
                return null
            }
        },
        changeListView(type: any) {
            this.showWorkers = type == 'workers'
            this.searchUser = !this.showWorkers
        },
        async changeRole(event: any) {
            const workerForm = {
                location: this.insLocation.id,
                user: this.selectedEmployee.user_data.id,
                role: event.target.value
            }
            
            if (navigator.onLine) {
                const postEntry = await updateUserRole(this.selectedEmployee.id, workerForm)
                this.parseQueryResult(postEntry)
            } else {
                const uniquekey = 'cache:wma:records'
                let cached: any = localStorage.getItem(uniquekey)
                if (cached) {
                    cached = JSON.parse(cached)
                } else {
                    cached = JSON.parse('[]')
                }
                cached.push({
                    'payloads': [this.selectedEmployee.id, workerForm],
                    'type': 'Update Employee Role',
                    'status': 'pending'})
                localStorage.setItem(uniquekey, JSON.stringify(cached))
                this.resetVariables()
                this.status = 'Operation Stored. Remember to synchronize when you are online.'
            }
            this.loadEmployees()
        },
        findWorkers() {
            this.changeListView('workers')
        },
        async findUsers() {
            if (this.userFilter.length < 3) {
                alert('Please, enter at least 3 characters.')
                return;
            }

            this.changeListView(null)
            this.users.splice(0, this.users.length)
            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/users/", this.userFilter))
                    this.users.splice(0, this.users.length)
                    for (const elem of resp.data) {
                        this.users.push({uname: elem["user_data"]["username"], tel: elem['telephone_number'], id: elem["id"]})
                    }
                } catch (err) { 
                    console.log(err)
                }
            } else {
                const uniquekey = 'cache:wma:all-users'
                const cached = localStorage.getItem(uniquekey)
                if (cached) {
                    const parsed = JSON.parse(cached)
                    console.log(parsed)
                    const filteredList = parsed.list.filter((el: any) => { return el["user__username"]?.indexOf(this.userFilter) > -1 || el["telephone_number"]?.indexOf(this.userFilter) > -1 })
                    for (const elem of filteredList) {
                        this.users.push({uname: elem["user__username"], tel: elem['telephone_number'], id: elem["id"]})
                    }
                }
            }
        },
        chooseEmployee(selection: any) {
            this.selectedEmployee = selection
        },
        choose(selection: any) {
            this.selectedUser = selection
            this.searchUser = false
            this.userFilter = ''
        },
        removeUser() {
            this.selectedUser = {} as any
        },
        async showMsgAlert(msg: string) {
            let resolveFunction: (confirm: boolean) => void;
            const promise = new Promise<boolean>(resolve => {
                resolveFunction = resolve;
            });
            const alert = await alertController.create({
                cssClass: 'status-alert',
                header: msg,
                message: 'Once confirmed, you cannot go back!',
                buttons: [
                    {
                        text: 'Okay',
                        handler: () => {
                            console.log('Confirmed')
                            resolveFunction(true)
                        },
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            console.log('Cancelled')
                            resolveFunction(false)
                        },
                    }
                ],
            });

            await alert.present();
            return promise
        },
        async revokeRole() {
            const confirm = await this.showMsgAlert('Are you sure to revoke the role?');
            if (confirm) {
                console.log('yes');

                if (navigator.onLine) {
                    const postEntry = await revokeUserRole(this.selectedEmployee.id)
                    this.parseQueryResult(postEntry)
                } else {
                    const uniquekey = 'cache:wma:records'
                    let cached: any = localStorage.getItem(uniquekey)
                    if (cached) {
                        cached = JSON.parse(cached)
                    } else {
                        cached = JSON.parse('[]')
                    }
                    cached.push({
                        'payloads': [this.selectedEmployee.id],
                        'type': 'Revoke Employee Role',
                        'status': 'pending'})
                    localStorage.setItem(uniquekey, JSON.stringify(cached))
                    this.resetVariables()
                    this.status = 'Operation Stored. Remember to synchronize when you are online.'
                }
                this.selectedEmployee = {} as any
                this.loadEmployees()
            } else {
                console.log('no');
            }
            return null
        },
        async addRole(userData: any, roleId: number) {
            const workerForm = {
                location: this.insLocation.id,
                user: userData.id,
                role: roleId,
                username: userData.uname,
            }
            
            if (navigator.onLine) {
                const postEntry = await addUserRole(workerForm)
                this.parseQueryResult(postEntry)
            } else {
                const uniquekey = 'cache:wma:records'
                let cached: any = localStorage.getItem(uniquekey)
                if (cached) {
                    cached = JSON.parse(cached)
                } else {
                    cached = JSON.parse('[]')
                }
                cached.push({
                    'payloads': [workerForm],
                    'type': 'Add Employee Role',
                    'status': 'pending'})
                localStorage.setItem(uniquekey, JSON.stringify(cached))
                this.resetVariables()
                this.status = 'Operation Stored. Remember to synchronize when you are online.'
            }
            this.loadEmployees()
        },
        parseQueryResult(resp: any) {
            console.log(resp)
            console.log(resp.status)
            if (resp?.status == 201) {
                
                this.resetVariables()
                this.status = 'Operation Confirmed.'
            }
            else if (resp?.status == 400) {
                this.status = JSON.stringify(resp.data).replaceAll(/[{\[\]}]/g, ' ')
            }
            else {
                this.status = JSON.stringify(resp.statusText)
            }
        }
    },
    watch: {
        insLocation: {
            handler: function(newVal) {
                if (Object.keys(newVal).length > 0) {
                    this.loadEmployees()
                }
            }
        },
        userFilter: {
            handler: function(newVal) {
                if (newVal.length > 3) {
                    this.findUsers()
                }
            }
        }
    }
})
