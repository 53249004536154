<template>
    <ion-content class="centered">
        <ion-grid class="mt-2" fixed="true">
            <ion-row>
                <ion-col class="ion-hide-xl-down" size="0.75"></ion-col>
                <ion-col size-xl="1.5" size-sm="2.7" offset-xl="0.5" offset-sm="0.2" v-for="[key, entries] of Object.entries(wasteList)" :key="key">
                    <b>{{ key }}</b>
                    <div class="mt-1">
                        <div class="selectable" @click="changeSelection(key, val)" v-for="val of entries" :key="val" v-bind:class="[isSelected(key, val) ? 'selected' : '']">{{ val?.attribute_data?.name || val.attribute_data }}</div>
                    </div>
                </ion-col>
                <ion-col class="ion-hide-xl-down" size="0.75"></ion-col>
            </ion-row>
            <ion-row style="justify-content: center; color: red">
                {{ error }}
            </ion-row>
            <ion-row class="bottom-right">
                <button class="home-btn mr-2" style="height: 30px; width: 90px;" @click="closeModal(true)">Register</button>
                <button class="home-btn mr-1" style="height: 30px; width: 90px;" @click="closeModal(false)">Cancel</button>
            </ion-row>
        </ion-grid>
    </ion-content>
</template>

<script lang="ts">
import { IonGrid, IonRow, IonCol, IonContent, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: 'Modal',
    components: { IonGrid, IonRow, IonCol, IonContent },
    data() {
        return {
            wasteList: {},
            selWaste: {} as any,
            amount: '',
            error: '',
            wastes: []
        }
    },
    mounted() {
        this.cacheWastes()

        this.loadAttributes()
    },
    methods: {
        async closeModal(insert: any) {
            if (insert) {
                const wasteObject = {
                    "waste_type": this.selWaste['Waste Type']?.id || null,
                    "shape": this.selWaste['Shape']?.id || null,
                    "colour": this.selWaste['Color']?.id || null,
                    "agg_state": this.selWaste['Aggregation']?.id || null,
                    "measure_unit": this.selWaste['Measure Unit']?.attribute_data || null
                }

                if (navigator.onLine) {
                    try {
                        const resp = await axios.post(''.concat(config['host_ip'], config['host_port'], '/waste/'), {'waste': wasteObject})
                        modalController.dismiss(resp.data)
                    } catch (err) { 
                        for (const [key, value] of Object.entries(err.response.data) as [string, any]) {
                            const formattedKey = key.replaceAll('_', ' ')
                            this.error = this.error.length == 0 ? ''.concat(this.error, formattedKey, ': ', value) : ''.concat(this.error, ', ', formattedKey, ': ', value)
                        }
                        console.log(err)
                    }
                } else {
                    const found = this.findWasteinCache(wasteObject)
                    if (found) {
                        modalController.dismiss(found)
                    }
                }
            } else {
                this.error = ''
                modalController.dismiss()
            }
        },
        changeSelection(category: string, attribute: any) {
            if (this.isSelected(category, attribute) && !this.selWaste[category]?.required) {
                this.selWaste[category] = {}
            } else {
                this.selWaste[category] = attribute
            }
        },
        isSelected(category: string, attribute: any) {
            return this.selWaste[category] == attribute
        },
        async loadAttributes() {
            const uniquekey = 'cache:wma:waste-attributes'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.wasteList = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/wastes-attributes"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.wasteList = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
            for (const [key, entries] of Object.entries(this.wasteList) as [string, any]) {
                if ( key == "Measure Unit" && entries.length == 1 ) {
                    entries[0]['required'] = true
                    this.selWaste[key] = entries[0]
                }
            }
        },
        async cacheWastes() {
            const uniquekey = 'cache:wma:all-wastes'
            const cached = localStorage.getItem(uniquekey)
            let parsed = {} as any
            if (cached) {
                parsed = JSON.parse(cached)
                this.wastes = parsed.list || []
            }

            const ts = (new Date()).toDateString()
            if (navigator.onLine && parsed?.timestamp != ts) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/wastes"))
                    localStorage.setItem(uniquekey, JSON.stringify({'list': resp.data, 'timestamp': ts}))
                    this.wastes = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        findWasteinCache(data: any) {
            let res = this.wastes.slice()
            for (const [key, entries] of Object.entries(data) as [string, any]) {
                res = res.filter(el => { return el[key] == entries })
            }
            if (res.length == 1) {
                return res[0]
            } else {
                this.error = 'Unable to retrieve this information in offline mode.'
                return null
            }
        }
    }
});
</script>

<style scoped>

.bottom-right {
    position: fixed;
    bottom: 10px;
    right: 10px;
}

.centered {
    text-align: center;
}

.selectable {
    padding: 5px 0px 5px 0px;
    border: 1px solid #2d8de8;
    border-radius: 10px;
    font-size: 0.8em;
    margin-bottom: 10%;
    cursor: pointer;
    user-select: none;
}

.selected {
    background: #2d8de8;
}
</style>
