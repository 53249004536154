
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import { IonGrid, IonRow, IonCol, IonIcon, modalController } from '@ionic/vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import WasteSelect from './modals/WasteSelect.vue';
import { saveFixStock } from '@/common/records';

export default defineComponent({
    name: "Fix Stock Inventory",
    components: {
        IonGrid, IonRow, IonCol, IonIcon,
    },
    data() {
        return {
            locations: [] as any[],
            stock: [] as any[],
            filteredList: [] as any[],
            insLocation: {} as any,
            reason: 'Stock Inventory Fix',
            status: '',
            filter: '',
            selectedWaste: {}
        }
    },
    mounted() {
        this.loadOwnLocation()
    }, 
    watch: {
        insLocation: {
            handler: function(newVal) {
                if (Object.keys(newVal).length > 0) {
                    this.loadStock()
                }
            }
        }
    },
    methods: {
        async loadOwnLocation() {
            const uniquekey = 'cache:wma:own-locations'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.locations = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/own_location/"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.locations = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }

            if(this.locations.length == 1) {
                this.insLocation = this.locations[0]
            }
        },
        resetVariables() {
            this.filteredList = [] as any[]
            if (this.locations.length > 1) {
                this.insLocation = {}
                this.stock = [] as any[]
            } else {
                this.loadStock()
            }
            this.status = ''
            this.filter = ''
        },
        async loadStock() {
            const uniquekey = 'cache:wma:stock-' + this.insLocation?.id
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                this.stock = JSON.parse(cached)
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/stock/", this.insLocation.id))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.stock = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
            this.filterStock()
        },
        filterStock() {
            this.filteredList.splice(0, this.filteredList.length)
            
            this.filteredList = this.stock.filter( (item: any) => { return item.description.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 })
        },
        async openWasteSelection() {
            const modal = await modalController.create({
                component: WasteSelect,
                cssClass: 'wasteSelectModal'
            })
            modal.present();

            modal.onDidDismiss().then((data: any) => {
                if (data.data) {

                    const found = this.stock.findIndex( (el: any) => { return el.waste_id == data.data.id })

                    // Move or Add on top
                    if (found > -1) {
                        const ref = this.stock[found]
                        this.stock.splice(found, 1);
                        this.stock.unshift(ref);
                    } else {
                        this.stock.unshift({'total_amount': 0.0, 
                            'waste_id': data.data.id,
                            'waste_type': data.data.waste_type_data?.attribute_data?.name,
                            'shape': data.data.shape_data?.attribute_data?.name,
                            'colour': data.data.colour_data?.attribute_data?.name,
                            'agg_state': data.data.agg_state_data?.attribute_data?.name,
                            'measure_unit': data.data.measure_unit,
                        })
                    }

                    this.filter = ''
                    this.filteredList = this.stock.slice()
                }
            });
        },
        modify(waste: any) {
            if (waste['change']) {
                waste['change'] = false
                // waste['update'] = 0.0
            } else {
                waste['change'] = true
                waste['oldValue'] = waste.total_amount
                document.getElementById('waste' + waste.waste_id)?.focus()
            }
        },
        checkValue(event: any, waste: any) {
            //if invalid character or unbounded, set min
            if (event.data != '.' && isNaN(event.data) || Number(event.target.value) < Number(event.target.min)) {
                event.target.value = Number(event.target.min)
                waste.total_amount = waste.oldValue
            }

        },
        async save() {
            const ptForm = {
                out_location: this.insLocation.id,
                in_user: this.$store.state.userDetail.id,
                reason: this.reason,
                wastes: [] as any[]
            }
            for(const w of this.stock) {
                if ('oldValue' in w && w.oldValue != w.total_amount) {
                    ptForm.wastes.push({
                        amount: (w.total_amount - w.oldValue).toFixed(2),
                        waste_id: w.waste_id
                    })
                }
            }
            if (ptForm.wastes.length == 0) {
                return
            }
            console.log(ptForm)
            
            if (navigator.onLine) {
                const postEntry = await saveFixStock(ptForm)
                this.parseQueryResult(postEntry)
            } else {
                const uniquekey = 'cache:wma:records'
                let cached: any = localStorage.getItem(uniquekey)
                if (cached) {
                    cached = JSON.parse(cached)
                } else {
                    cached = JSON.parse('[]')
                }
                cached.push({
                    'payloads': [ptForm],
                    'type': 'Fix Stock Inventory',
                    'status': 'pending'})
                localStorage.setItem(uniquekey, JSON.stringify(cached))
                this.resetVariables()
                this.status = 'Operation Stored. Remember to synchronize when you are online.'
            }
        },
        parseQueryResult(resp: any) {
            console.log(resp)
            console.log(resp.status)
            if (resp?.status == 201) {
                
                this.resetVariables()
                this.status = 'Operation Confirmed.'
            }
            else if (resp?.status == 400) {
                this.status = JSON.stringify(resp.data).replaceAll(/[{}_]/g, ' ')
            }
            else {
                this.status = JSON.stringify(resp.data)
            }
        }
    }
})
