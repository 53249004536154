
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import { IonGrid, IonRow, IonCol, IonIcon, modalController } from '@ionic/vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import WasteSelect from './modals/WasteSelect.vue';
import { updatePricing } from '@/common/records';

export default defineComponent({
    name: "Manage Price List",
    components: {
        IonGrid, IonRow, IonCol, IonIcon
    },
    data() {
        return {
            locations: [] as any[],
            prices: [] as any[],
            filteredList: [] as any[],
            insLocation: {} as any,
            status: '',
            filter: '',
            convRate: {} as any,
        }
    },
    mounted() {
        this.loadOwnLocation()
        this.loadConversionRate()
    }, 
    watch: {
        insLocation: {
            handler: function(newVal) {
                if (Object.keys(newVal).length > 0) {
                    this.loadPrices()
                }
            }
        }
    },
    methods: {
        async loadOwnLocation() {
            const uniquekey = 'cache:wma:own-locations'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.locations = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/own_location/"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.locations = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }

            if(this.locations.length == 1) {
                this.insLocation = this.locations[0]
            }
        },
        async loadConversionRate() {
            const uniquekey = 'cache:wma:conversion-rate'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.convRate = parsed[0]
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/conversion_rate/"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.convRate = resp.data[0]
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        resetVariables() {
            if (this.locations.length > 1) {
                this.insLocation = {}
                this.prices = [] as any[]
            } else {
                this.loadPrices()
            }
            this.filteredList = [] as any[]
            this.status = ''
            this.filter = ''
        },
        parseDate(dt: any) {
            if (dt) {
                return (new Date(dt)).toLocaleDateString()
            }
            return '-'
        },
        async loadPrices() {
            const uniquekey = 'cache:wma:pricing-' + this.insLocation?.id
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.prices = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/pricing/", this.insLocation?.id))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.prices =  resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
            this.filteredList = this.prices.slice()
        },
        filterPrices() {
            this.filteredList.splice(0, this.filteredList.length)
            
            this.filteredList = this.prices.filter( (item: any) => { return item.description.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 })
        },
        async openWasteSelection() {
            const modal = await modalController.create({
                component: WasteSelect,
                cssClass: 'wasteSelectModal'
            })
            modal.present();

            modal.onDidDismiss().then((data: any) => {
                if (data.data) {
                    const found = this.prices.findIndex( (el: any) => { return el.waste_id == data.data.id })

                    // Move or Add on top
                    if (found > -1) {
                        const ref = this.prices[found]
                        this.prices.splice(found, 1);
                        this.prices.unshift(ref);
                    } else {
                        this.prices.unshift({
                            'price': 0.0, 
                            'waste_id': data.data.id,
                            'waste_type': data.data.waste_type_data?.attribute_data?.name,
                            'shape': data.data.shape_data?.attribute_data?.name,
                            'colour': data.data.colour_data?.attribute_data?.name,
                            'agg_state': data.data.agg_state_data?.attribute_data?.name,
                        })
                    }

                    this.filter = ''
                    this.filteredList = this.prices.slice()
                }
            });
        },
        modify(waste: any) {
            if (waste['change']) {
                waste['change'] = false
                waste['oldValue'] = waste.price
            } else {
                waste['change'] = true
                waste['oldValue'] = waste.price
                document.getElementById('waste' + waste.waste_id)?.focus()
            }
        },
        checkValue(event: any, waste: any) {
            //if invalid character reset
            if (event.data != '.' && isNaN(event.data) || Number(event.target.value) < Number(event.target.min)) {
                event.target.value = waste['oldValue']
                waste.price = waste['oldValue']
            }

        },
        async save() {
            const priceForm = {
                location: this.insLocation.id,
                wastes: [] as any[]
            }
            console.log(this.prices)
            for(const w of this.prices) {
                if ('oldValue' in w && w.oldValue != w.price) {
                    priceForm.wastes.push({
                        price:  parseFloat(w.price),
                        waste: w.waste_id
                    })
                }
            }
            if (priceForm.wastes.length == 0) {
                return
            }
            console.log(priceForm)
            
            if (navigator.onLine) {
                const postEntry = await updatePricing(priceForm)
                this.parseQueryResult(postEntry)
            } else {
                const uniquekey = 'cache:wma:records'
                let cached: any = localStorage.getItem(uniquekey)
                if (cached) {
                    cached = JSON.parse(cached)
                } else {
                    cached = JSON.parse('[]')
                }
                cached.push({
                    'payloads': [priceForm],
                    'type': 'Update Price List',
                    'status': 'pending'})
                localStorage.setItem(uniquekey, JSON.stringify(cached))
                this.status = 'Operation Stored. Remember to synchronize when you are online.'
            }
        },
        parseQueryResult(resp: any) {
            console.log(resp)
            console.log(resp.status)
            if (resp?.status == 201) {
                
                this.resetVariables()
                this.status = 'Operation Confirmed.'
            }
            else if (resp?.status == 400) {
                this.status = JSON.stringify(resp.data).replaceAll(/[{}_]/g, ' ')
            }
            else {
                this.status = JSON.stringify(resp.data)
            }
        }
    }
})
