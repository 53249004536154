
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import { IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import { saveMoneyOutcome } from '@/common/records';

export default defineComponent({
    name: "Money Outcome",
    components: {
        IonGrid, IonRow, IonCol, IonIcon
    },
    data() {
        return {
            convRate: {} as any,
            selectedUser: {} as any,
            userFilter: '',
            locations: [] as any[],
            insLocation: {} as any,
            reason: 'Money Outcome',
            creditAmount: null as any,
            status: '',
            searchUser: false,
            users: [] as any[],
        }
    },
    mounted() {
        this.loadOwnLocation()
        this.loadConversionRate()
    },
    watch: {
        userFilter: {
            handler: function(newVal) {
                if (newVal.length > 3) {
                    this.findUsers()
                }
            }
        }
    },
    methods: {
        async loadOwnLocation() {
            const uniquekey = 'cache:wma:own-locations'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.locations = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/own_location/"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.locations = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }

            if(this.locations.length == 1) {
                this.insLocation = this.locations[0]
            }
        },
        async loadConversionRate() {
            const uniquekey = 'cache:wma:conversion-rate'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.convRate = parsed[0]
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/conversion_rate/"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.convRate = resp.data[0]
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        resetVariables() {
            this.selectedUser = {}
            this.userFilter = ''
            if (this.locations.length > 1) {
                this.insLocation = {}
            }
            this.status = ''
            this.searchUser = false
            this.users = [] as any[]
            this.creditAmount = null as any
        },
        async findUsers() {
            if (this.userFilter.length < 3) {
                alert('Please, enter at least 3 characters.')
                return;
            }

            this.searchUser = true
            this.users.splice(0, this.users.length)

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/users/", this.userFilter))
                    this.users.splice(0, this.users.length)
                    for (const elem of resp.data) {
                        this.users.push({uname: elem["user_data"]["username"], tel: elem['telephone_number'], id: elem["id"]})
                    }
                } catch (err) { 
                    console.log(err)
                }
            } else {
                const uniquekey = 'cache:wma:all-users'
                const cached = localStorage.getItem(uniquekey)
                if (cached) {
                    const parsed = JSON.parse(cached)
                    console.log(parsed)
                    const filteredList = parsed.list.filter((el: any) => { return el["user__username"]?.indexOf(this.userFilter) > -1 || el["telephone_number"]?.indexOf(this.userFilter) > -1 })
                    for (const elem of filteredList) {
                        this.users.push({uname: elem["user__username"], tel: elem['telephone_number'], id: elem["id"]})
                    }
                }
            }
        },
        choose(selection: any) {
            this.searchUser = false
            this.userFilter = ''
            this.selectedUser = selection
        },
        removeUser() {
            this.users.splice(0, this.users.length)
            this.selectedUser = {}
        },
        checkValue(event: any) {
            if (event.data != '.' && isNaN(event.data) || Number(event.target.value) < Number(event.target.min)) {
                event.target.value = Number(event.target.min)
                this.creditAmount = Number(event.target.min)
            }
        },
        checkEmptyForm() {
            if( Object.keys(this.insLocation).length == 0 ) {
                this.status = 'Please enter your location.'
                return true
            }

            if( Object.keys(this.selectedUser).length == 0 ) {
                this.status = 'Please enter the user who delivered waste.'
                return true
            }

            if (!this.creditAmount || this.creditAmount == 0) {
                this.status = 'Please insert a valid credit.'
                return true
            }

            return false
        },
        async save() {
            const ftForm = {
                in_location: this.insLocation.id,
                out_username: this.selectedUser.uname,
                reason: 'Money Outcome',
                is_money: true,
                value: -1 * this.creditAmount
            }
            
            if (navigator.onLine) {
                const postEntry = await saveMoneyOutcome(ftForm)
                this.parseQueryResult(postEntry)
            } else {
                const uniquekey = 'cache:wma:records'
                let cached: any = localStorage.getItem(uniquekey)
                if (cached) {
                    cached = JSON.parse(cached)
                } else {
                    cached = JSON.parse('[]')
                }
                cached.push({
                    'payloads': [ftForm],
                    'type': 'Money Outcome',
                    'status': 'pending'})
                localStorage.setItem(uniquekey, JSON.stringify(cached))
                this.resetVariables()
                this.status = 'Operation Stored. Remember to synchronize when you are online.'
            }
        },
        parseQueryResult(resp: any) {
            console.log(resp)
            console.log(resp.status)
            if (resp?.status == 201) {
                const value = JSON.parse(JSON.stringify(this.creditAmount))
                const currency = JSON.parse(JSON.stringify(this.convRate.currency))
                const user = JSON.parse(JSON.stringify(this.selectedUser.uname))
                this.resetVariables()
                this.status = ''.concat(
                    'Operation Confirmed. ',
                    value,
                    ' ',
                    currency,
                    ' were withdrawn from the account of ',
                    user,
                    '.'
                )
            }
            else if (resp?.status == 400) {
                this.status = JSON.stringify(resp.data).replaceAll(/[{}_]/g, ' ')
            }
            else {
                this.status = JSON.stringify(resp.statusText)
            }
        }
    }
})
