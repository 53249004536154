
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import { IonGrid, IonRow, IonCol, IonIcon, modalController } from '@ionic/vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import WasteSelect from './modals/WasteSelect.vue';
import { saveWasteDelivery } from '@/common/records';

export default defineComponent({
    name: "Waste Delivery Out",
    components: {
        IonGrid, IonRow, IonCol, IonIcon
    },
    data() {
        return {
            selectedLocation: {} as any,
            locationFilter: '',
            wastes: [] as any[],
            own_locations: [] as any[],
            locations: [] as any[],
            filteredLocations: [] as any[],
            insLocation: {} as any,
            stock: [] as any[],
            reason: 'Waste Delivery - Out',
            convRate: {} as any,
            instantCharge: false,
            status: '',
            searchLocation: false,
            priceList: [] as any[],
        }
    },
    mounted() {
        this.loadOwnLocation()
        this.loadLocations()
        this.loadConversionRate()
    },
    computed: {
        wasteValue() {
            let value = 0.0
            console.log(this.wastes)
            for (const w of this.wastes) {
                value += parseFloat(w.amount) * parseFloat(w.waste.price)
            }
            return value.toFixed(2)
        },
        otherLocations(): any[] {
            return this.locations.filter( (el: any) => { return el.id != this.insLocation?.id })
        }
    }, 
    watch: {
        insLocation: {
            handler: function(newVal) {
                if (Object.keys(newVal).length > 0) {
                    this.loadStock()
                }
            }
        },
        selectedLocation: {
            handler: function(newVal, oldVal) {
                if (Object.keys(newVal).length > 0) {
                    this.loadPrices()
                    return
                }
                //if unselecting location then remove prices
                if (Object.keys(oldVal).length > 0) {
                    this.priceList = [] as any[]
                    this.updateWastePrices()
                }
            }
        },
        locationFilter: {
            handler: function(newVal) {
                if (newVal.length > 3) {
                    this.findLocations()
                }
            }
        }
    },
    methods: {
        async loadOwnLocation() {
            const uniquekey = 'cache:wma:own-locations'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.own_locations = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/own_location/"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.own_locations = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }

            if(this.own_locations.length == 1) {
                this.insLocation = this.own_locations[0]
            }
        },
        async loadLocations() {
            const uniquekey = 'cache:wma:locations'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.locations = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/locations/"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.locations = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }

            if(this.locations.length == 1) {
                this.insLocation = this.locations[0]
            }
        },
        async loadConversionRate() {
            const uniquekey = 'cache:wma:conversion-rate'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.convRate = parsed[0]
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/conversion_rate/"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.convRate = resp.data[0]
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        resetVariables() {
            this.selectedLocation = {}
            this.locationFilter = ''
            this.filteredLocations = [] as any[]
            this.wastes = [] as any[]
            if (this.own_locations.length > 1) {
                this.insLocation = {}
            }
            this.instantCharge = false
            this.status = ''
            this.searchLocation = false
            this.priceList = [] as any[]
        },
        findLocations() {
            this.searchLocation = true
            this.filteredLocations = this.otherLocations.filter((el: any) => { return el.name.toLowerCase().includes(this.locationFilter.toLowerCase()) || el.address.toLowerCase().includes(this.locationFilter.toLowerCase()) })
        },
        choose(selection: any) {
            this.searchLocation = false
            this.locationFilter = ''
            this.selectedLocation = selection
        },
        removeLocation() {
            this.selectedLocation = {}
        },
        async loadStock() {
            const uniquekey = 'cache:wma:stock-' + this.insLocation.id
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                this.stock = JSON.parse(cached)
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/stock/", this.insLocation.id))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.stock = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        async loadPrices() {
            const uniquekey = 'cache:wma:pricing-' + this.selectedLocation.id
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                for( const i of parsed) {
                    const actualPrice = i.price || 0.0
                    this.priceList.push({price: actualPrice, currency: i.currency, wasteId: i.waste_id})
                }
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/pricing/", this.selectedLocation.id))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    for( const i of resp.data) {
                        const actualPrice = i.price || 0.0
                        this.priceList.push({price: actualPrice, currency: i.currency, wasteId: i.waste_id})
                    }
                } catch (err) { 
                    console.log(err)
                }
            }
            
            this.updateWastePrices()
        },
        getAvailableAmount(waste_id: number) {
            const found = this.stock.find( (el: any) => { return waste_id == el.waste_id })
            if (found) {
                return found.total_amount
            } else {
                return 0.0
            }
        },
        checkValue(event: any, wasteRef: any) {
            if (event.data != '.' && isNaN(event.data) ) {
                event.target.value = 0.0
                wasteRef.amount = 0.0
            }

            if (Number(event.target.value) > Number(event.target.max)) {
                event.target.value = Number(event.target.max)
                wasteRef.amount = Number(event.target.max)
            }
        },
        async openWasteSelection() {
            const modal = await modalController.create({
                component: WasteSelect,
                cssClass: 'wasteSelectModal'
            })
            modal.present();

            modal.onDidDismiss().then((data: any) => {
                if (data.data) {
                    console.log(data.data)
                    console.log(this.priceList)

                    // check if waste is already in list
                    const inList = this.wastes.find( (el: any) => { return el.waste.waste.id == data.data.id })
                    if (inList) {
                        this.status = 'Selected Waste is already in list'
                        return
                    }

                    const found = this.priceList.find( (el: any) => { return el.wasteId == data.data.id })
                    console.log(found)

                    if (found) {
                        this.wastes.push({'amount': 0.0, 'waste': {'waste': data.data, 'price': found.price}})
                    } else {
                        this.wastes.push({'amount': 0.0, 'waste': {'waste': data.data, 'price': 0.0}})
                    }
                    console.log(this.wastes)
                }
            });
        },
        updateWastePrices() {
            for (const w of this.wastes) {
                console.log(w)
                const found = this.priceList.find( (el: any) => { return el.wasteId == w.waste.waste.id })
                console.log(found)
                if (found) {
                    w.waste = found
                } else {
                    w.waste = {'waste': w.waste.waste, 'price': 0.0}
                }
            }
        },
        remove(waste: any) {
            const idx = this.wastes.indexOf(waste)
            if (idx > -1) {
                this.wastes.splice(idx, 1);
            }
        },
        checkEmptyForm() {
            if( Object.keys(this.insLocation).length == 0 ) {
                this.status = 'Please enter your location.'
                return true
            }

            if( Object.keys(this.selectedLocation).length == 0 ) {
                this.status = 'Please enter the receiving location.'
                return true
            }

            if (this.wastes.length == 0) {
                this.status = 'Please insert some waste to register'
                return true
            }

            return false
        },
        async save() {
            if (this.checkEmptyForm()) {
                return
            }

            const ptForm = {
                in_location: this.insLocation.id,
                out_location: this.selectedLocation.id,
                reason: this.reason,
                is_executed: false,
                is_passive: true,
                wastes: [] as any[]
            } as any

            const ftForm = {
                in_location: this.selectedLocation.id,
                out_location: this.insLocation.id,
                reason: this.reason,
                is_executed: false,
                is_money: true,
                wastes: [] as any[]
            } as any

            for (const w of this.wastes) {
                ptForm.wastes.push({
                    amount: w.amount,
                    waste_id: w.waste.waste.id
                })

                const value = parseFloat(w.amount) * parseFloat(w.waste.price) * parseFloat(this.convRate.value)
                ftForm.wastes.push({
                    amount: w.amount,
                    value: value.toFixed(2),
                    waste_id: w.waste.waste.id
                })
            }

            if (navigator.onLine) {
                const postEntry = await saveWasteDelivery(ftForm, ptForm, false, 0.0)
                this.parseQueryResult(postEntry)
            } else {
                const uniquekey = 'cache:wma:records'
                let cached: any = localStorage.getItem(uniquekey)
                if (cached) {
                    cached = JSON.parse(cached)
                } else {
                    cached = JSON.parse('[]')
                }
                cached.push({
                    'payloads': [ftForm, ptForm, false, 0.0],
                    'type': 'Waste Delivery - Out',
                    'status': 'pending'})
                localStorage.setItem(uniquekey, JSON.stringify(cached))
                this.resetVariables()
                this.status = 'Operation Stored. Remember to synchronize when you are online.'
            }
        },
        parseQueryResult(resp: any) {
            console.log(resp)
            console.log(resp.status)
            if (resp?.status == 201) {
                
                this.resetVariables()
                this.status = 'Operation Confirmed.'
            }
            else if (resp?.status == 400) {
                this.status = JSON.stringify(resp.data).replaceAll(/[{}_]/g, ' ')
            }
            else {
                this.status = JSON.stringify(resp.statusText)
            }
        }
    }
})
