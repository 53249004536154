<template>
    <ion-grid>
        <ion-row>
            <ion-col size-xl="2.5" size-xs="0.1"></ion-col>
            <ion-col size-xl="7" size-xs="11">
                <div class="card center" style="height: auto">
                    <b>Manage Price List</b>
                    <div class="form scale90p">
                        <div class="input-bottom mt-2 flex" v-if="locations.length > 1">
                            <label class="input-color" style="min-width: fit-content;" for="location1">Your Location</label>
                            <select class="searchbar" style="margin-left: auto; margin-right: 2%; text-align-last: end" name="location1" id="location1" v-model="insLocation">
                                <option v-for="l of locations" :key="l" :value="l">{{ l.name }} ({{ l.locationtype.name }})</option>
                            </select>
                        </div>

                        <div class="flex">
                            <div class="input-bottom flex" style="width: 100%;">
                                <input class="searchbar" type="text" placeholder="Search" v-model="filter" @input="filterPrices" />
                            </div>
                            <div>
                                <button class="btn-custom2 bold-text" @click="openWasteSelection()">Add New Waste +</button>
                            </div>
                        </div>
                        <div class="mt-2">
                            <table>
                                <thead>
                                    <tr class="table-header">
                                        <th class="cell-border">Waste Type</th>
                                        <th class="cell-border">Item</th>
                                        <th class="cell-border">Color</th>
                                        <th class="cell-border">Aggregation</th>
                                        <th class="cell-border">Price ({{ convRate.currency }})</th>
                                        <th class="cell-border">Last Edit</th>
                                        <th class="cell-border" style="width: 50px;">Edit</th>
                                    </tr>
                                </thead>
                                <tbody class="hidescrollbar">
                                    <tr v-for="item of filteredList" :key="item">
                                        <td class="cell-border">{{ item.waste_type || '-' }}</td>
                                        <td class="cell-border">{{ item.shape || '-' }}</td>
                                        <td class="cell-border">{{ item.colour || '-' }}</td>
                                        <td class="cell-border">{{ item.agg_state || '-' }}</td>
                                        <td class="cell-border">
                                            <input :id="'waste' + item.waste_id" class="searchbar" style="width: 85%" type="number" :min="0.0" step=".1" @input="checkValue($event, item)" v-model="item.price" v-bind:class="[item['change'] ? '' : 'disabled']" />
                                        </td>
                                        <td class="cell-border">{{ parseDate(item.execution_date) }}</td>
                                        <td class="cell-border" style="width: 50px;">
                                            <ion-icon @click="modify(item)" name="ch-forward"></ion-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="mt-2" style="text-align: center; min-height: 5.5vh; color: red">
                    {{ status }}
                </div>
                <div class="mt-1" style="text-align: center">
                    <button class="btn-custom bold-text" @click="save()">Submit</button>
                    <button class="btn-custom" @click="resetVariables()">Clear</button>
                </div>
            </ion-col>
            <ion-col size-xl="2.5" size-xs="0.1"></ion-col>
        </ion-row>
    </ion-grid>
</template>

<script lang="ts">
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import { IonGrid, IonRow, IonCol, IonIcon, modalController } from '@ionic/vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import WasteSelect from './modals/WasteSelect.vue';
import { updatePricing } from '@/common/records';

export default defineComponent({
    name: "Manage Price List",
    components: {
        IonGrid, IonRow, IonCol, IonIcon
    },
    data() {
        return {
            locations: [] as any[],
            prices: [] as any[],
            filteredList: [] as any[],
            insLocation: {} as any,
            status: '',
            filter: '',
            convRate: {} as any,
        }
    },
    mounted() {
        this.loadOwnLocation()
        this.loadConversionRate()
    }, 
    watch: {
        insLocation: {
            handler: function(newVal) {
                if (Object.keys(newVal).length > 0) {
                    this.loadPrices()
                }
            }
        }
    },
    methods: {
        async loadOwnLocation() {
            const uniquekey = 'cache:wma:own-locations'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.locations = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/own_location/"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.locations = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }

            if(this.locations.length == 1) {
                this.insLocation = this.locations[0]
            }
        },
        async loadConversionRate() {
            const uniquekey = 'cache:wma:conversion-rate'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.convRate = parsed[0]
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/conversion_rate/"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.convRate = resp.data[0]
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        resetVariables() {
            if (this.locations.length > 1) {
                this.insLocation = {}
                this.prices = [] as any[]
            } else {
                this.loadPrices()
            }
            this.filteredList = [] as any[]
            this.status = ''
            this.filter = ''
        },
        parseDate(dt: any) {
            if (dt) {
                return (new Date(dt)).toLocaleDateString()
            }
            return '-'
        },
        async loadPrices() {
            const uniquekey = 'cache:wma:pricing-' + this.insLocation?.id
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.prices = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/pricing/", this.insLocation?.id))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.prices =  resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
            this.filteredList = this.prices.slice()
        },
        filterPrices() {
            this.filteredList.splice(0, this.filteredList.length)
            
            this.filteredList = this.prices.filter( (item: any) => { return item.description.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 })
        },
        async openWasteSelection() {
            const modal = await modalController.create({
                component: WasteSelect,
                cssClass: 'wasteSelectModal'
            })
            modal.present();

            modal.onDidDismiss().then((data: any) => {
                if (data.data) {
                    const found = this.prices.findIndex( (el: any) => { return el.waste_id == data.data.id })

                    // Move or Add on top
                    if (found > -1) {
                        const ref = this.prices[found]
                        this.prices.splice(found, 1);
                        this.prices.unshift(ref);
                    } else {
                        this.prices.unshift({
                            'price': 0.0, 
                            'waste_id': data.data.id,
                            'waste_type': data.data.waste_type_data?.attribute_data?.name,
                            'shape': data.data.shape_data?.attribute_data?.name,
                            'colour': data.data.colour_data?.attribute_data?.name,
                            'agg_state': data.data.agg_state_data?.attribute_data?.name,
                        })
                    }

                    this.filter = ''
                    this.filteredList = this.prices.slice()
                }
            });
        },
        modify(waste: any) {
            if (waste['change']) {
                waste['change'] = false
                waste['oldValue'] = waste.price
            } else {
                waste['change'] = true
                waste['oldValue'] = waste.price
                document.getElementById('waste' + waste.waste_id)?.focus()
            }
        },
        checkValue(event: any, waste: any) {
            //if invalid character reset
            if (event.data != '.' && isNaN(event.data) || Number(event.target.value) < Number(event.target.min)) {
                event.target.value = waste['oldValue']
                waste.price = waste['oldValue']
            }

        },
        async save() {
            const priceForm = {
                location: this.insLocation.id,
                wastes: [] as any[]
            }
            console.log(this.prices)
            for(const w of this.prices) {
                if ('oldValue' in w && w.oldValue != w.price) {
                    priceForm.wastes.push({
                        price:  parseFloat(w.price),
                        waste: w.waste_id
                    })
                }
            }
            if (priceForm.wastes.length == 0) {
                return
            }
            console.log(priceForm)
            
            if (navigator.onLine) {
                const postEntry = await updatePricing(priceForm)
                this.parseQueryResult(postEntry)
            } else {
                const uniquekey = 'cache:wma:records'
                let cached: any = localStorage.getItem(uniquekey)
                if (cached) {
                    cached = JSON.parse(cached)
                } else {
                    cached = JSON.parse('[]')
                }
                cached.push({
                    'payloads': [priceForm],
                    'type': 'Update Price List',
                    'status': 'pending'})
                localStorage.setItem(uniquekey, JSON.stringify(cached))
                this.status = 'Operation Stored. Remember to synchronize when you are online.'
            }
        },
        parseQueryResult(resp: any) {
            console.log(resp)
            console.log(resp.status)
            if (resp?.status == 201) {
                
                this.resetVariables()
                this.status = 'Operation Confirmed.'
            }
            else if (resp?.status == 400) {
                this.status = JSON.stringify(resp.data).replaceAll(/[{}_]/g, ' ')
            }
            else {
                this.status = JSON.stringify(resp.data)
            }
        }
    }
})
</script>

<style scoped>
.form {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
}

table {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    font-size: 0.9em;
}

table thead, table tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}

.table-header {
    font-weight: bold;
}

.cell-border {
    border-bottom: 1px solid #a9d8f7;
    border-right: 1px solid #a9d8f7;
    border-left: 1px solid #a9d8f7;
    padding: 7px 0px 7px 7px;
}

.cell-border:first-child {
    border-left: 0;
}

.cell-border:last-child {
    border-right: 0;
}

table > tbody {
    max-height: 200px;
    height: 200px;
    overflow-y:scroll;
    display: block;
}

.hidescrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.disabled {
    pointer-events: none;
}
</style>