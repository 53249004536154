<template>
  <ion-page>
    <ion-content>
      <ion-grid class="h-100p">
        <ion-row class="h-100p">
            <ion-col size="2.2" class="sidebar ion-hide-md-down" style="min-width: 10.4vw">
              <div class="sidebar-entry clickable" v-for="opt of permittedOptions" :key="opt" v-bind:class="[isActive(opt.link) ? 'active' : '']" @click="select(opt)">{{ opt.name }}</div>
            </ion-col>

            <ion-col>
                <div class="mt-5" style="text-align: center">
                    <b>Operations</b>
                </div>
                <component class="mt-2" v-if="selected" :is="getSelection?.component"></component>
            </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent, IonGrid, IonCol, IonRow } from '@ionic/vue';
import { defineComponent } from 'vue';
import WasteDeliveryIn from './components/operationTemplates/WasteDeliveryIn.vue';
import WasteDeliveryOut from './components/operationTemplates/WasteDeliveryOut.vue';
import MoneyOutcome from './components/operationTemplates/MoneyOutcome.vue';
import FixStockInventory from './components/operationTemplates/FixStockInventory.vue';
import ManagePriceList from './components/operationTemplates/ManagePriceList.vue';
import EditUserInfo from './components/operationTemplates/EditUserInfo.vue';
import EditEmployeesInfo from './components/operationTemplates/EditEmployeesInfo.vue';

export default defineComponent({
  name: 'Operations',
  components: {
    IonPage, IonContent, IonGrid, IonCol, IonRow,
    WasteDeliveryIn, WasteDeliveryOut, MoneyOutcome, FixStockInventory, ManagePriceList, EditUserInfo, EditEmployeesInfo
  },
  data() {
    return {
        options: [
            {name: 'Register Waste Delivery - In', link: '/operations/1', component: 'WasteDeliveryIn'},
            {name: 'Register Waste Delivery - Out', link: '/operations/2', component: 'WasteDeliveryOut'},
            {name: 'Register Money Outcome', link: '/operations/3', component: 'MoneyOutcome'},
            {name: 'Fix Stock Inventory', link: '/operations/4', component: 'FixStockInventory'},
            {name: 'Manage Price List', link: '/operations/5', restricted: true, component: 'ManagePriceList'},
            {name: 'Edit User Information', link: '/operations/6', restricted: true, component: 'EditUserInfo'},
            {name: 'Edit Employees Information', link: '/operations/7', restricted: true, component: 'EditEmployeesInfo'},
        ],
        selected: this.$route.params?.op || null as any
    }
  },
  computed: {
    isManager(): boolean {
      return this.$store.state?.userDetail?.user_data?.groups.includes('Manager')
    },
    permittedOptions(): Array<any> {
      if (this.isManager) {
        return this.options
      } else {
        return this.options.filter( (el: any) => { return !el.restricted })
      }
    },
    getSelection(): any {
        return this.permittedOptions.find( (el: any) => { return this.$route.path.includes(el.link)})
    }
  },
  methods: {
    isActive(link: string) {
        return this.$route.path.includes(link)
    },
    select(element: any) {
        this.$router.push(element.link)
    }
  }
});
</script>

<style scoped>
.sidebar {
    background: white;
}

.sidebar-entry {
    padding: 15px 10px 15px 30px;
}

.sidebar-entry:first-child {
    padding-top: 4.4vh;
}

.active {
  font-weight: bold;
  color: #2d8de8;
}
</style>