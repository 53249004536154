
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import SingleDateSelector from '../SingleDateSelector.vue';
import { saveUserProfile } from '@/common/records';

export default defineComponent({
    name: "Edit User Info",
    components: {
        IonGrid, IonRow, IonCol,
        SingleDateSelector
    },
    data() {
        return {
            title: 'Edit User Information',
            isUpdate: false,
            isUpload: false,
            selectedUser: {
                user_data: {
                    username: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                    groups: [
                        "Subscriber"
                    ]
                },
                date_birth: null,
                gender: 'X',
                user_img: null,
                address: null,
                telephone_number: null,
                identity_document_type: null,
                identity_document: null,
                user_type: null
            } as any,
            userFilter: '',
            status: '',
            searchUser: false,
            users: [] as any[],
            utypes: [] as any[],
            showForm: false,
            resetPassword: false,
            isNewUser: false,
        }
    },
    computed: {
        getImageURL(): any {
            if (this.selectedUser.user_img.length > 0 && this.isUpdate && !this.isUpload) {
                return config['host_ip'] + config['host_port'] + this.selectedUser.user_img
            } else {
                return this.selectedUser.user_img
            }
        },
        getNetworkStatus() {
            if (navigator) {
                return navigator.onLine
            } else {
                return false
            }
        }
    },
    mounted() {
        this.loadUserTypes()
    }, 
    watch: {
        userFilter: {
            handler: function(newVal) {
                if (newVal.length > 3) {
                    this.findUsers()
                }
            }
        }
    },
    methods: {
        resetVariables() {
            this.title = 'Edit User Information'
            this.isNewUser = false
            this.isUpdate = false
            this.isUpload = false
            this.selectedUser = {
                user_data: {
                    username: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                    groups: [
                        "Subscriber"
                    ]
                },
                date_birth: null,
                gender: 'X',
                user_img: null,
                address: null,
                telephone_number: null,
                identity_document_type: null,
                identity_document: null,
                user_type: null
            } as any
            this.userFilter = ''
            this.status = ''
            this.searchUser = false
            this.users = [] as any[]
            this.showForm = false
            this.resetPassword = false
        },
        async loadUserTypes() {
            const uniquekey = 'cache:wma:user-types'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.utypes = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/usertypes/"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.utypes = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        findUsers() {
            if (this.userFilter.length < 3) {
                alert('Please, enter at least 3 characters.')
                return;
            }

            this.searchUser = true
            this.users.splice(0, this.users.length)
            axios.get(''.concat(config['host_ip'], config['host_port'], "/users/", this.userFilter))
            .then((response) => {
                this.users = response.data
            })
            .catch((error) => {
                console.log(error);
            });
        },
        removeUser() {
            this.users.splice(0, this.users.length)
            this.selectedUser = {
                user_data: {
                    username: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                    groups: [
                        "Subscriber"
                    ]
                },
                date_birth: null,
                gender: 'X',
                user_img: null,
                address: null,
                telephone_number: null,
                identity_document_type: null,
                identity_document: null,
                user_type: null
            } as any
        },
        checkField(field: string) {
            return field && field.length > 0
        },
        choose(selection: any) {
            this.selectedUser = selection
            if(this.selectedUser?.user_data?.username?.length > 0) {
                this.isUpdate = true
            } else {
                this.isNewUser = true
                this.title = 'Register New User'
            }
            this.searchUser = false
            this.userFilter = ''
            this.showForm = true
        },
        getDateBirth(res: any) {
            this.selectedUser.date_birth = res
        },
        changeResetFlag() {
            this.resetPassword = !this.resetPassword
        },
        fileUpload(fileInput: any) {
            if (fileInput.target.files.length > 0) {
                const fileData = (fileInput.target.files[0]);

                if (fileData.type.match(/image\/*/) == null) {
                    return;
                }

                const reader = new FileReader();
                reader.readAsDataURL(fileData);
                reader.onload = () => {
                    this.isUpload = true
                    this.selectedUser.user_img = reader.result;
                };
            }
        },
        async save() {
            const payload = JSON.parse(JSON.stringify(this.selectedUser))
            if(!this.isUpload) {
                delete payload.user_img
            }

            if (this.isUpdate) {
                let url = ''.concat(config['host_ip'], config['host_port'], "/user-detail")
                if (this.resetPassword) {
                    url = url + '-reset/'
                } else {
                    url = url + '/'
                }
                axios.put(url + this.selectedUser.id, payload)
                .then((response) => {
                    this.parseQueryResult(response)
                })
                .catch((error) => { this.parseQueryResult(error.response) });
            } else {
                if (navigator.onLine) {
                    const postEntry = await saveUserProfile(payload)
                    this.parseQueryResult(postEntry)
                } else {
                    const uniquekey = 'cache:wma:records'
                    let cached: any = localStorage.getItem(uniquekey)
                    if (cached) {
                        cached = JSON.parse(cached)
                    } else {
                        cached = JSON.parse('[]')
                    }
                    cached.push({
                        'payloads': [payload],
                        'type': 'Register New User',
                        'status': 'pending'})
                    localStorage.setItem(uniquekey, JSON.stringify(cached))
                    this.resetVariables()
                    this.status = 'Operation Stored. Remember to synchronize when you are online.'
                }
            }
        },
        parseQueryResult(resp: any) {
            console.log(resp)
            console.log(resp.status)
            if (resp?.status == 201) {
                
                this.resetVariables()
                this.status = 'Registration Confirmed.'
            }
            else if (resp?.status == 400) {
                this.status = JSON.stringify(resp.data).replaceAll(/[{\[\]}_]/g, ' ')
            }
            else {
                this.status = JSON.stringify(resp.statusText)
            }
        }
    }
})
